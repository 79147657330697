import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
 
export default function Protected(props) {
    const navigate = useNavigate(); // Corregido el nombre de la variable
    const { Component } = props;
    
    useEffect(() => {
        let login = localStorage.getItem("login");
        if (!login) {
            localStorage.setItem("loginStatus", "Please login to view dashboard!");
            navigate("/", { replace: true });
        }
    }, [navigate]); // Incluir navigate en el array de dependencias
 
    return <Component />;
}
