import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Carousel from "react-spring-3d-carousel";
import { useSpring, animated } from "react-spring";
import Styles from "./Card.module.css";
import "./proyectos.css";
import imagenLocal from "../../componentes/assets/obras/Novo Park.jpg";
import imagenLocal2 from "../../componentes/assets/obras/Escuela Militar.jpg";
import imagenLocal3 from "../../componentes/assets/obras/Ecuador 2.jpg";
import imagenLocal4 from "../../componentes/assets/obras/Infinity Velamar.jpg";
import imagenLocal5 from "../../componentes/assets/obras/La Alcaldesa.jpg";
import imagenLocal6 from "../../componentes/assets/obras/La cabana.jpg";
import imagenLocal7 from "../../componentes/assets/obras/Nataniel.jpg";
import imagenLocal8 from "../../componentes/assets/obras/Plaza Maipu.jpg";
import imagenLocal9 from "../../componentes/assets/obras/Raul Labee.jpg";
import imagenLocal10 from "../../componentes/assets/obras/San Pablo.jpg";

const Card = ({ imagen, title, description }) => {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    opacity: 1,
    transform: show ? "scale(1.03)" : "scale(1)",          
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });

  return (
    <div>
      <animated.div
        className={Styles.card}
        style={props3}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
      >
        <img src={imagen} alt="Imagen" />
        <div className={Styles["text-container"]}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </animated.div>
    </div>
  );
};

const ProyectosCarousel = () => {
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);

  const cards = [
    { key: uuidv4(), content: <Card imagen={imagenLocal} title="Novo Park" description="Av. General Bulnes #7, Reñaca  M2: 21.425" /> },
    { key: uuidv4(), content: <Card imagen={imagenLocal2} title="Escuela Militar" description="Av. Presidente Riesco #4680 M2: N/A"/> },
    { key: uuidv4(), content: <Card imagen={imagenLocal3} title="Ecuador" description="Av. Ecuador #5079, Comuna: Estacion Central, M2: 20.673"/> },
    { key: uuidv4(), content: <Card imagen={imagenLocal4} title="Infinity Velamar" description="Av. Edmundo Eluchans #2105, Viña del Mar M2: 26.657"/> },
    { key: uuidv4(), content: <Card imagen={imagenLocal5} title="La Alcaldesa" description="Los Leones con Francisco Bilbao, Ñuñoa M2: N/A"/> },
    { key: uuidv4(), content: <Card imagen={imagenLocal6} title="La Cabaña" description="Av. Las Condes #12751, Comuna: Las Condes M2: 12.100"/> },
    { key: uuidv4(), content: <Card imagen={imagenLocal7} title="Nataniel" description="Nataniel Cox #655 M2: 7.533"/> },
    { key: uuidv4(), content: <Card imagen={imagenLocal8} title="Plaza Maipú" /> },
    { key: uuidv4(), content: <Card imagen={imagenLocal9} title="Raúl Labbé" /> },
    { key: uuidv4(), content: <Card imagen={imagenLocal10} title="San Pablo" description="San Pablo esq. Coronel Robles M2: 7.000"/> },
  ];

  useEffect(() => {
    setOffsetRadius(200);
    setShowArrows(false);

    const interval = setInterval(() => {
      setGoToSlide((prevSlide) => (prevSlide + 1) % cards.length);
    }, 5000); // Cambia cada 10 segundos

    return () => clearInterval(interval);
  }, [cards.length]);

  const table = cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  return (
    <div className="background-container">
      <div className="centralizar">
        <div style={{ width: "100%", height: "500px", margin: "0 auto" }}>
          <Carousel
            slides={table}
            goToSlide={goToSlide}
            offsetRadius={offsetRadius}
            showNavigation={showArrows}
            dragEnabled={true} // Habilitar el desplazamiento táctil
          />
        </div>
      </div>
    </div>
  );
};

export default ProyectosCarousel;
