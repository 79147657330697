
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './componentes/navbar/navbar';
import Footer from './componentes/footer/footer'
import Home from './paginas/home/home';
import Nosotros from './paginas/nosotros/nosotros'
import Servicios from './paginas/servicios/servicios'
import Contacto from './paginas/contacto/contacto';
import Proyectos from './paginas/proyectos/proyectos';
import Clientes from './paginas/clientes/clientes';
import Login from './componentes/Login/Login'
import Dashboard from './componentes/Dashboard/Dashboard';
import Protected from './componentes/Protected/Protected';


function App() {
  return (
    <Router>
      <Navbar/>
        <div >
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="/quien" element={<Nosotros />} />
            <Route path="/proyectos" element={<Proyectos />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
          </Routes>
          <Footer />
        </div>
    </Router>
  );
}

export default App;
