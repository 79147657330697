import React, { useState } from 'react';
import videoSource from '../../componentes/assets/video/MIAT.mp4';
import './nosotros.css';

import { Offcanvas } from 'react-bootstrap';
import textos from '../../componentes/assets/dinamico/textos.json';

const Nosotros = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [offcanvasContent, setOffcanvasContent] = useState({});

  const handleShowOffcanvas = (seccion) => {
    setShowOffcanvas(true);
    setOffcanvasContent(textos[seccion] || {});
  };

  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  const handleClickOffcanvas = () => {
    setShowOffcanvas(false);
  };

  return (
    <div>
      <video autoPlay loop muted className="video">
        <source src={videoSource} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
      {/* Botones */}
      <div className="button-container">
        <button className="button" onClick={() => handleShowOffcanvas('nosotros')}>
          NOSOTROS
        </button>
        <button className="button" onClick={() => handleShowOffcanvas('mision')}>
          MISIÓN
        </button>
        <button className="button" onClick={() => handleShowOffcanvas('vision')}>
          VISIÓN
        </button>
      </div>
      {/* Fin de los botones */}

      {/* Offcanvas */}
      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end" className="custom-offcanvas" onClick={handleClickOffcanvas}>
        <Offcanvas.Header className='custom-title'>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="custom-offcanvas-body" onClick={(e) => e.stopPropagation()}>
          <p className='titulo-cuerpo'>{offcanvasContent.titulo}</p>
          <p>{offcanvasContent.contenido || 'Sin contenido'}</p>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Fin de Offcanvas */}
    </div>
  );
};

export default Nosotros;
