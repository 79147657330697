import React, { useState } from 'react';
import './Crear.css';

const Crear = () => {
  const [formData, setFormData] = useState({
    codigoObra: '',
    codigoCliente: '',
    nombre: '',
    direccion: '',
    administrador: '',
    encargadoCalidad: '',
    supervisorCalidad: '',
    gerenteProyecto: '',
    gerenteDivision: '',
    cantidadTorres: '',
    pisosPorTorre: '',
    departamentosPorPiso: '',
    metrosConstruidos: '',
    subcontratos: '',
    empresaSubcontrato: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('form.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.text())
    .then(data => {
      console.log('Success:', data);
      alert('Los datos se han insertado correctamente');
      // Aquí podrías redirigir o hacer otras acciones después de la creación exitosa
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Hubo un error al insertar los datos');
    });
  };

  return (
    <div className="crear-form-container">
      <h2 style={{ textAlign: "center", color: "rgb(21, 6, 136)"}}>Crear Nuevo Proyecto</h2>
      <form onSubmit={handleSubmit} className="crear-form" style={{ marginTop: "35px" }}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="codigoObra">Código de Obra (Obligatorio)</label>
            <input
              type="text"
              id="codigoObra"
              name="codigoObra"
              value={formData.codigoObra}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="codigoCliente">Código Cliente (Obligatorio)</label>
            <input
              type="text"
              id="codigoCliente"
              name="codigoCliente"
              value={formData.codigoCliente}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="nombre">Nombre (Obligatorio)</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="direccion">Dirección (Obligatorio)</label>
            <input
              type="text"
              id="direccion"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="administrador">Administrador</label>
            <input
              type="text"
              id="administrador"
              name="administrador"
              value={formData.administrador}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="encargadoCalidad">Encargado de Calidad</label>
            <input
              type="text"
              id="encargadoCalidad"
              name="encargadoCalidad"
              value={formData.encargadoCalidad}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="supervisorCalidad">Supervisor de Calidad</label>
            <input
              type="text"
              id="supervisorCalidad"
              name="supervisorCalidad"
              value={formData.supervisorCalidad}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="gerenteProyecto">Gerente de Proyecto</label>
            <input
              type="text"
              id="gerenteProyecto"
              name="gerenteProyecto"
              value={formData.gerenteProyecto}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="gerenteDivision">Gerente de División</label>
            <input
              type="text"
              id="gerenteDivision"
              name="gerenteDivision"
              value={formData.gerenteDivision}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="cantidadTorres">Cantidad de Torres</label>
            <input
              type="number"
              id="cantidadTorres"
              name="cantidadTorres"
              value={formData.cantidadTorres}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="pisosPorTorre">Pisos por Torre</label>
            <input
              type="number"
              id="pisosPorTorre"
              name="pisosPorTorre"
              value={formData.pisosPorTorre}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="departamentosPorPiso">Departamentos por Piso</label>
            <input
              type="number"
              id="departamentosPorPiso"
              name="departamentosPorPiso"
              value={formData.departamentosPorPiso}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="metrosConstruidos">Metros Construidos</label>
            <input
              type="number"
              id="metrosConstruidos"
              name="metrosConstruidos"
              value={formData.metrosConstruidos}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="subcontratos">Subcontratos</label>
            <input
              type="text"
              id="subcontratos"
              name="subcontratos"
              value={formData.subcontratos}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="empresaSubcontrato">Empresa Subcontrato</label>
            <input
              type="text"
              id="empresaSubcontrato"
              name="empresaSubcontrato"
              value={formData.empresaSubcontrato}
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit" className="submit-button">Crear</button>
      </form>
    </div>
  );
};

export default Crear;
