import React from 'react';
import { Carousel } from 'react-bootstrap';
import './home.css';

// Importación de las imágenes del carrusel
import logoEmpresa1 from '../../componentes/assets/carrusel/3l sin fondo.png'; 
import logoEmpresa2 from '../../componentes/assets/carrusel/2560px-Marca-uc.svg.png';
import logoEmpresa3 from '../../componentes/assets/carrusel/Accor_Logo_2020.png';
import logoEmpresa4 from '../../componentes/assets/carrusel/Capital Advisors sin fondo.png';
import logoEmpresa5 from '../../componentes/assets/carrusel/INACAP Sin fondo.png';
import logoEmpresa6 from '../../componentes/assets/carrusel/Imagina.png';
import logoEmpresa7 from '../../componentes/assets/carrusel/Inarco sin fondo.png';
import logoEmpresa8 from '../../componentes/assets/carrusel/Ingespro sin fondo.png';
import logoEmpresa9 from '../../componentes/assets/carrusel/Logo GIP Sin fondo.png';

const Home = () => {
  return (
    <div className="home-container">
      <div className="background-image"></div>
      <div className="carrusel-container">
        <h1 className="titulo">HAN CONFIADO EN NOSOTROS</h1>
        <Carousel controls={false} indicators={false}>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa1} alt="Logo Empresa 1" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa2} alt="Logo Empresa 2" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa3} alt="Logo Empresa 3" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa4} alt="Logo Empresa 4" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa5} alt="Logo Empresa 5" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa6} alt="Logo Empresa 6" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa7} alt="Logo Empresa 7" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa8} alt="Logo Empresa 8" className="logo-carousel" />
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={logoEmpresa9} alt="Logo Empresa 9" className="logo-carousel" />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Home;
