import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Crear from '../DasboardItems/Crear/Crear';
import Editar from "../DasboardItems/Editar/Editar";
import Main from "../DasboardItems/Main/Main";

function Dashboard() {
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false); // Estado para controlar la visibilidad del menú desplegable
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const user = localStorage.getItem('user');

  const logoutSubmit = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleToggleSubMenu = () => {
    setShowSubMenu(!showSubMenu); // Alternar la visibilidad del submenú
  };

  const handleSetActiveComponent = (component) => {
    setActiveComponent(component);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '250px', backgroundColor: 'rgba(190, 190, 199, 0.705)', minHeight: '100vh', color: 'rgb(21, 6, 136)', padding: '20px' }}>
        <span className="me-3" style={{ fontSize: '18px', marginTop: '40px' }}>Hola, {user}</span>
        <div className="submenu-title" style={{ cursor: 'pointer', fontSize: '24px', marginTop: '40px' }} onClick={handleToggleSubMenu}>
          Proyectos
        </div>
        {showSubMenu && (
          <ul className="list-group list-group-flush" style={{ marginTop: '10px' }}>
            <li style={{ cursor: 'pointer', fontSize: '20px', marginLeft: '30px' }} onClick={() => handleSetActiveComponent('form')}>Crear Obra</li>
            <li style={{ cursor: 'pointer', fontSize: '20px', marginLeft: '30px' }} onClick={() => handleSetActiveComponent('other')}>Consultar</li>
          </ul>
        )}
        <button className="btn btn-salir mt-4" type="button" onClick={logoutSubmit}>Salir</button>
      </div>
      <div style={{ flex: 1, paddingTop: 5 }}>
        {activeComponent === 'dashboard' && <Main />}
        {activeComponent === 'form' && <Crear />}
        {activeComponent === 'other' && <Editar />}
      </div>
    </div>
  );
}

export default Dashboard;
