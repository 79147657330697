import React from 'react';
import './clientes.css'; // Archivo de estilos
import logo1 from '../../componentes/assets/logos/Logo GIP Sin fondo.png';
import logo2 from '../../componentes/assets/logos/Imagina.png';
import logo3 from '../../componentes/assets/logos/Accor_Logo_2020.png';
import logo4 from '../../componentes/assets/logos/Ingespro sin fondo.png';
import logo5 from '../../componentes/assets/logos/Capital Advisors sin fondo.png';
import logo6 from '../../componentes/assets/logos/3l sin fondo.png';
import logo7 from '../../componentes/assets/logos/INACAP Sin fondo.png';
import logo8 from '../../componentes/assets/logos/Inarco sin fondo.png';
import logo9 from '../../componentes/assets/logos/Reccius.png';
import logo10 from '../../componentes/assets/logos/accion.png';
import logo11 from '../../componentes/assets/logos/Gestaria.png';
import logo12 from '../../componentes/assets/logos/Duoc.png';

const logosData = [
  { id: 1, src: logo1, alt: 'Logo Empresa construcción GIP' },
  { id: 2, src: logo2, alt: 'Logo Inmobiliaria Imagina' },
  { id: 3, src: logo3, alt: 'Logo Empresa Accord' },
  { id: 4, src: logo4, alt: 'Logo Ingespro' },
  { id: 5, src: logo5, alt: 'Logo Capital Advisors' },
  { id: 6, src: logo6, alt: 'Logo empresa 3L' },
  { id: 7, src: logo7, alt: 'Logo INACAP' },
  { id: 8, src: logo8, alt: 'Logo Empresa Inarco' },
  { id: 9, src: logo9, alt: 'Logo Empresa Reccius' },
  { id: 10, src: logo10, alt: 'Logo Inmobiliaria Accion' },
  { id: 11, src: logo11, alt: 'Logo Empresa Gestaria' },
  { id: 12, src: logo12, alt: 'Logo Duoc' },
];

const Clientes = () => {
  return (
    <div>
      <div className='background-container2'></div>
      <div className="clientes-container">
        <h1 className='centrarlo'>Nuestros Clientes</h1>
        <div className="cliente-row">
          {logosData.slice(0, 4).map((logo, index) => (
            <div className='prueba1' key={index}><img src={logo.src} alt={logo.alt} className="cliente-logo" /></div>
          ))}
        </div>
        <div className="cliente-row">
          {logosData.slice(4, 8).map((logo, index) => (
            <div className='prueba1' key={index}><img src={logo.src} alt={logo.alt} className="cliente-logo" /></div>
          ))}
        </div>
        <div className="cliente-row">
          {logosData.slice(8).map((logo, index) => (
            <div className='prueba1' key={index}><img src={logo.src} alt={logo.alt} className="cliente-logo" /></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clientes;
