// Barra.js

import React from 'react';
import logo from '../assets/logos/MIAT C sin fondo.png';
import logo1 from '../assets/logos/ACT2.png';
import logo2 from '../assets/logos/Logo2.png';
import { Navbar, Container, Nav,} from 'react-bootstrap';
import './navbar.css'; 
function Barra() {
  return (
    <Navbar expand="lg" className="navbar-custom">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo2} alt="Logo" className="logo-image" />
          <img src={logo} alt="Logo" className="logo-image2" />
        </Navbar.Brand>
        <Navbar.Toggle  />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="navbar-custom">
            <Nav.Link href="quien">QUIÉNES SOMOS</Nav.Link>
            <Nav.Link href="servicios">SERVICIOS</Nav.Link>
            <Nav.Link href="proyectos">PROYECTOS</Nav.Link>
            <Nav.Link href="clientes">CLIENTES</Nav.Link>
            <Nav.Link href="contacto">CONTACTO</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav.Link href='Login'><img src={logo1} alt="Logo" className="logo-image3" /></Nav.Link>
      </Container>
    </Navbar>
  );
}

export default Barra;
