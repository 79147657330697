import React from 'react';
import './contacto.css';

const Contacto = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const nombre = e.target.nombre.value;
    const apellido = e.target.apellido.value;
    const correo = e.target.correo.value;
    const asunto = e.target.area.value;
    const mensaje = e.target.mensaje.value;

    const cuerpoCorreo = `Nombre: ${nombre} ${apellido}\nCorreo Electrónico: ${correo}\nAsunto: ${asunto}\nMensaje: ${mensaje}`;

    let correoDestino = '';

    switch (asunto) {
      case 'ventas':
        correoDestino = 'rmorel@miat.cl';
        break;
      case 'soporte':
        correoDestino = 'mdelgado@miat.cl';
        break;
      case 'general':
        correoDestino = 'c.lucero@miat.cl';
        break;
      case 'reclamos':
        correoDestino = 'mdelgado@miat.cl';
        break;
      default:
        correoDestino = 'rmorel@miat.cl';
    }

    const mailtoLink = `mailto:${correoDestino}?subject=${encodeURIComponent(asunto)}&body=${encodeURIComponent(cuerpoCorreo)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="contacto-container">
      <div className="background-container10"></div>
    
      <div className="formulario-container">
        <form onSubmit={handleSubmit} className="formulario">
          <div className="campo">
            <label htmlFor="nombre">Nombre:</label>
            <input type="text" id="nombre" name="nombre" required />
          </div>

          <div className="campo">
            <label htmlFor="apellido">Apellido:</label>
            <input type="text" id="apellido" name="apellido" required />
          </div>

          <div className="campo">
            <label htmlFor="correo">Correo Electrónico:</label>
            <input type="email" id="correo" name="correo" required />
          </div>

          <div className="campo">
            <label htmlFor="area">Asunto</label>
            <select id="area" name="area">
              <option value="ventas">Cotizaciones</option>
              <option value="soporte">Reclamos o Sugerencias</option>
              <option value="general">Trabaja con nosotros</option>
              <option value="reclamos">Consulta</option>
            </select>
          </div>

          <div className="campo">
            <label htmlFor="mensaje">Mensaje:</label>
            <textarea id="mensaje" name="mensaje" required></textarea>
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
      
      <div className="mapa-container">
        <div className="mapa">
          <iframe
            title="Ubicación de la empresa"
            width="500"
            height="400"
            frameBorder="0"
            style={{ border: 0 }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.310992995993!2d-70.6604934!3d-33.4412033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5aed922da8f%3A0x57018e2956b4cf06!2sAgustinas%201687%2C%208340449%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1704347617772!5m2!1ses-419!2scl"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
