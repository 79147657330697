import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import imagen2 from '../../componentes/assets/logos/MIAT C sin fondo.png';

function Login() {
    const navigate = useNavigate();
    const [usuario, setUsuario] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");

    const handleInputChange = (e, type) => {
        setError("");
        if (type === "user") {
            setUsuario(e.target.value);
        } else if (type === "pass") {
            setPass(e.target.value);
        }
    }

    const loginSubmit = () => {
        if (usuario.trim() === "" || pass.trim() === "") {
            setError("Todos los campos son obligatorios!");
            return;
        }

        var url = "login.php";
        var headers = {
            "Accept": "application/json",
            "Content-type": "application/json"
        };
        var Data = {
            user: usuario,
            pass: pass
        };
        
        fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(Data)
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.result === "Invalid username!" || response.result === "Invalid password!") {
                setError(response.result);
            } else {
                setMsg(response.result);
                localStorage.setItem("login", true);
                localStorage.setItem('user', usuario);
                navigate("/dashboard");
            }
        })
        .catch((err) => {
            setError("Ocurrió un error: " + err.message);
            console.log(err);
        });
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            loginSubmit();
        }
    };

    return (
        <section className="vh-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(190, 190, 199, 0.705)'}}>
            <div className="card text-center" style={{ borderRadius: '1rem', maxWidth: '450px', maxHeight: '425px', padding: '50px' , marginTop: '5px'}}>
                <div className="card-body p-4 p-lg-5 text-black">
                    {error && <div style={{ color: '#842029' }}><b>{error}</b></div>}
                    {msg && <div style={{ color: '#badbcc' }}><b>{msg}</b></div>}
                    <div className="d-flex align-items-center mb-3 pb-1 justify-content-center">
                        <i className="fas fa-cubes fa-2x me-3" style={{ color: '#ff6219' }} />
                        <span className="h1 fw-bold mb-0">
                            <img src={imagen2} alt="MIAT C sin fondo" style={{ width: '150px', height: 'auto' }} />
                        </span>
                    </div>
                    <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>Acceso de Usuarios</h5>
                    <div className="mb-4">
                        <label className="form-label" htmlFor="username">Usuario</label>
                        <input
                            type="text"
                            id="username"
                            className="form-control form-control-lg"
                            value={usuario}
                            onChange={(e) => handleInputChange(e, "user")}
                            style={{ borderRadius: '25px' }}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label" htmlFor="pass">Clave</label>
                        <input
                            type="password"
                            id="pass"
                            className="form-control form-control-lg"
                            value={pass}
                            onChange={(e) => handleInputChange(e, "pass")}
                            style={{ borderRadius: '25px' }}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div>
                        <input
                            type="submit"
                            value="Login"
                            className="btn btn-dark btn-lg btn-block"
                            onClick={loginSubmit}
                            style={{ borderRadius: '25px', background: 'rgb(21, 6, 136)' }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
