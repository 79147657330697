import React, { useState } from 'react';
import './Editar.css';

const Editar = () => {
  const [searchData, setSearchData] = useState({
    codigoObra: '',
    codigoCliente: ''
  });

  const [formData, setFormData] = useState({
    codigoObra: '',
    codigoCliente: '',
    nombre: '',
    direccion: '',
    administrador: '',
    encargadoCalidad: '',
    supervisorCalidad: '',
    gerenteProyecto: '',
    gerenteDivision: '',
    cantidadTorres: '',
    pisosPorTorre: '',
    departamentosPorPiso: '',
    metrosConstruidos: '',
    subcontratos: '',
    empresaSubcontrato: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    // Verificar que al menos uno de los campos esté lleno
    if (!searchData.codigoObra && !searchData.codigoCliente) {
      alert('Debe ingresar al menos uno de los criterios de búsqueda.');
      return;
    }

    fetch('buscar.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchData),
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        alert(data.error);
      } else {
        setFormData(data);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Hubo un error al buscar el proyecto');
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    fetch('actualizar.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('Proyecto actualizado exitosamente');
        setFormData({
          codigoObra: '',
          codigoCliente: '',
          nombre: '',
          direccion: '',
          administrador: '',
          encargadoCalidad: '',
          supervisorCalidad: '',
          gerenteProyecto: '',
          gerenteDivision: '',
          cantidadTorres: '',
          pisosPorTorre: '',
          departamentosPorPiso: '',
          metrosConstruidos: '',
          subcontratos: '',
          empresaSubcontrato: ''
        });
      } else {
        alert('Hubo un error al actualizar el proyecto');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Hubo un error al actualizar el proyecto');
    });
  };

  return (
    <div className="editar-form-container">
      <h2 style={{ textAlign: "center", color: "rgb(21, 6, 136)"}}>Buscar Proyecto para Editar</h2>
      <form onSubmit={handleSearch} className="editar-form">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="codigoObra">Código de Obra</label>
            <input
              type="text"
              id="codigoObra"
              name="codigoObra"
              value={searchData.codigoObra}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="codigoCliente">Código Cliente</label>
            <input
              type="text"
              id="codigoCliente"
              name="codigoCliente"
              value={searchData.codigoCliente}
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit" className="search-button">Buscar</button>
      </form>

      {formData.codigoObra && (
        <form onSubmit={handleUpdate} className="editar-form">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="codigoObra">Código de Obra</label>
              <input type="text" name="codigoObra" value={formData.codigoObra} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="codigoCliente">Código de Cliente</label>
              <input type="text" name="codigoCliente" value={formData.codigoCliente} onChange={handleFormChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="nombre">Nombre de Obra</label>
              <input type="text" name="nombre" value={formData.nombre} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="direccion">Dirección</label>
              <input type="text" name="direccion" value={formData.direccion} onChange={handleFormChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="administrador">Administrador de Obra</label>
              <input type="text" name="administrador" value={formData.administrador} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="encargadoCalidad">Encargado de Calidad</label>
              <input type="text" name="encargadoCalidad" value={formData.encargadoCalidad} onChange={handleFormChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="supervisorCalidad">Supervisor de Calidad</label>
              <input type="text" name="supervisorCalidad" value={formData.supervisorCalidad} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="gerenteProyecto">Gerente del Proyecto</label>
              <input type="text" name="gerenteProyecto" value={formData.gerenteProyecto} onChange={handleFormChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="gerenteDivision">Gerente de División</label>
              <input type="text" name="gerenteDivision" value={formData.gerenteDivision} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="cantidadTorres">Cantidad de Torres</label>
              <input type="text" name="cantidadTorres" value={formData.cantidadTorres} onChange={handleFormChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="pisosPorTorre">Pisos por Torre</label>
              <input type="text" name="pisosPorTorre" value={formData.pisosPorTorre} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="departamentosPorPiso">Departamentos por Piso</label>
              <input type="text" name="departamentosPorPiso" value={formData.departamentosPorPiso} onChange={handleFormChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="metrosConstruidos">Metros Construidos</label>
              <input type="text" name="metrosConstruidos" value={formData.metrosConstruidos} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="subcontratos">Subcontratos</label>
              <input type="text" name="subcontratos" value={formData.subcontratos} onChange={handleFormChange} />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="empresaSubcontrato">Empresa de Subcontrato</label>
            <input type="text" name="empresaSubcontrato" value={formData.empresaSubcontrato} onChange={handleFormChange} />
          </div>
          <button type="submit" className="save-button">Guardar Cambios</button>
        </form>
      )}
    </div>
  );
};

export default Editar;
