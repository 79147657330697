import React, { useState } from 'react';
import imagen1 from '../../componentes/assets/cartas/coorproyectos.png';
import imagen2 from '../../componentes/assets/cartas/desarrollo.png';
import imagen8 from '../../componentes/assets/cartas/duediligence.png';
import imagen4 from '../../componentes/assets/cartas/asesores.png';
import imagen5 from '../../componentes/assets/cartas/estudioprop.png';
import imagen6 from '../../componentes/assets/cartas/Inspeccion.png';
import imagen7 from '../../componentes/assets/cartas/Recepcion.png';
import imagen3 from '../../componentes/assets/cartas/Revision proyectos.png';
import info from '../../componentes/assets/dinamico/card.json';
import './servicios.css'; // Importamos el archivo CSS para los estilos

function Servicios() {
  const imagenes = [imagen1, imagen2, imagen3, imagen4, imagen5, imagen6, imagen7, imagen8];

  // Estado para controlar si la tarjeta está volteada o no
  const [flippedCardIndex, setFlippedCardIndex] = useState(null);

  // Función para manejar el clic en una tarjeta y cambiar su estado de volteada
  const handleCardClick = (index) => {
    setFlippedCardIndex(index === flippedCardIndex ? null : index);
  };

  // Generamos las tarjetas basadas en los datos del JSON
  const cards = imagenes.map((imagen, index) => (
    <div
      key={index}
      className={`card-serv ${flippedCardIndex === index ? 'flipped' : ''}`}
      onClick={() => handleCardClick(index)}
    >
      <div className="front-face">
        <img className='imagen' src={imagen} alt={`Imagen ${index + 1}`} />
        <div className="card-body1">
          <h2>{info[index].titulo}</h2>
        </div>
      </div>
      <div className="back-face">
        <p>{info[index].texto}</p> {/* Agrega aquí el contenido del lado B */}
      </div>
    </div>
  ));

  return (
    <div>
      <div className='background-container8'></div>
      <div className="wallpaper2"></div> {/* Div para el papel tapiz */}
      <div className="card-container1">
        {cards}
      </div>
    </div>
  );
}

export default Servicios;
