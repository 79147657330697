import React, { useState, useEffect } from 'react';
import './Main.css';

const Main = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString();
  };

  return (
    <div className="main-container">
      <div className="datetime">
        <div className="date">{formatDate(dateTime)}</div>
        <div className="time">{formatTime(dateTime)}</div>
      </div>
    </div>
  );
};

export default Main;
