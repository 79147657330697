import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons';
import './footer.css';

function Footer() {
  return (
    <footer className="custom-footer">
      <div className="footer-container">
        <div>
          <a href="/contacto" className="footer-text link-white">
            Dirección: Agustinas #1687, piso 5, Santiago de Chile
          </a>
        </div>
        <div>
          <p className="footer-text">
            Contáctanos: {"  "}
            <a href="https://www.linkedin.com/company/miat-ingenieria/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>{"  "}
            <a href="#">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>{"  "}
            <a href="/contacto">
              <FontAwesomeIcon icon={farEnvelope} />
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;